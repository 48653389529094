<template>
    <main
        id="js-page-content"
        class="page-content justify-content-center"
        role="main"
    >
        <div class="col-xl-8 col-lg-8 col-md-8 m-auto">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body">
                    <div class="p-5">
                        <div class="text-center">
                            <img
                                class="img-fluid m-1"
                                src="@/assets/logo.jpg"
                            />
                            <h1 class="h2 text-primary">
                                {{ $t('auth.reset_password.title') }}
                            </h1>
                        </div>

                        <form class="user mb-4" method="POST">
                            <div class="form-group row mb-2">
                                <input
                                    id="email"
                                    v-model="email"
                                    autocomplete="email"
                                    autofocus
                                    class="form-control form-control-user"
                                    name="email"
                                    placeholder="Email"
                                    required
                                    type="email"
                                />
                            </div>
                            <p
                                v-if="isSubmit && mailMessage"
                                class="has-text-danger font-bold text-center is-size-7 pb-2"
                            >
                                {{ mailMessage }}
                            </p>
                            <div class="form-group row mb-2">
                                <input
                                    id="password"
                                    v-model="password"
                                    autocomplete="current-password"
                                    class="form-control form-control-user"
                                    name="password"
                                    :placeholder="
                                        $t('auth.reset_password.password')
                                    "
                                    required
                                    type="password"
                                />
                            </div>
                            <p
                                v-if="isSubmit && passwordMessage"
                                class="has-text-danger font-bold text-center is-size-7"
                            >
                                {{ passwordMessage }}
                            </p>

                            <div class="form-group row mb-2">
                                <input
                                    id="confirmPassword"
                                    v-model="confirmPassword"
                                    autocomplete="current-password"
                                    class="form-control form-control-user"
                                    name="confirmPassword"
                                    :placeholder="
                                        $t(
                                            'auth.reset_password.confirm_password'
                                        )
                                    "
                                    required
                                    type="password"
                                />
                            </div>
                            <button
                                v-show="!loading"
                                class="btn btn-primary btn-user btn-block"
                                @click.prevent="resetPwd"
                            >
                                {{ $t('auth.reset_password.submit') }}
                            </button>

                            <button
                                v-show="loading"
                                class="btn btn-primary btn-user btn-block waves-effect waves-themed"
                                disabled="disabled"
                                type="button"
                            >
                                <span
                                    aria-hidden="true"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                ></span>
                                {{ $t('auth.reset_password.submit') }}
                            </button>
                            <div v-if="isSubmit && successMessage">
                                <p
                                    class="has-text-danger font-bold text-center is-size-7"
                                >
                                    {{ successMessage }}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <Toaster ref="toaster" />
    </main>
</template>

<style scoped>
.h2 {
    margin: 0.5em 0 1em 0;
}
</style>

<script>
import { mapActions } from 'vuex'
import Toaster from '@/components/UI/Helper/Toaster'

export default {
    name: 'PasswordReset',
    components: {
        Toaster
    },
    data: function () {
        return {
            email: '',
            password: '',
            confirmPassword: '',
            isSubmit: false,
            loading: false,
            mailMessage: '',
            passwordMessage: '',
            successMessage: '',
            mailregExp:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            tokenResetPwd: ''
        }
    },
    computed: {
        messagePasswordMismatch() {
            return this.$t('error.validation.password_mismatch')
        },
        messageTitle() {
            return this.$t('error.title')
        }
    },
    created() {
        this.tokenResetPwd = this.$route.params.token
    },
    methods: {
        ...mapActions('auth', ['resetPassword']),
        resetPwd: function () {
            // this.loading = true
            this.isSubmit = true
            this.validMail(this.email)
            this.validPassword(this.password, this.confirmPassword)
            !this.mailMessage && !this.passwordMessage
                ? this.resetPassword({
                      email: this.email,
                      password: this.password,
                      token_reset_password: this.tokenResetPwd
                  })
                      .then(() => {
                          this.successMessage = this.$t(
                              'auth.reset_password.success_message'
                          )
                          this.loading = false
                      })
                      .finally(() => {
                          this.$router.push('/sanctum')
                      })
                      .catch((error) => {
                          this.loading = false
                          error['password_mismatch'] =
                              this.errorPasswordMismatch
                          error['title'] = this.errorTitle
                          this.$store.dispatch('dispatchError', error)
                      })
                : (this.loading = false)
        },
        // vérifie la confirmation du mot de passe (correspondance entre les deux mots de passes saisies)
        validPassword(pwd, confirmpwd) {
            if (!pwd) {
                this.passwordMessage = this.$t('error.validation.required')
            } else if (pwd !== confirmpwd) {
                this.passwordMessage = this.$t(
                    'error.validation.password_mismatch'
                )
            } else {
                this.passwordMessage = ''
            }
        },
        // vérifie la saisie du champ email
        validMail(email) {
            if (!email) {
                this.mailMessage = this.$t('error.validation.required')
            } else if (!this.mailregExp.test(email)) {
                this.mailMessage = this.$t('error.validation.mail_valid')
            } else {
                this.mailMessage = ''
            }
        }
    },
    mounted() {}
}
</script>
